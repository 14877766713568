<template>
  <div class="upload-box">
    <div class="img-item" v-for="(src,index) in list" :key="index">
      <div class="img-box">
        <img :src="src" alt />
        <div class="img-shadow">
          <img class="img-del" @click="delImg(index)" src="@/assets/images/upload/del.png" alt />
          <img class="img-show" @click="showImg(index)" src="@/assets/images/upload/show.png" alt />
        </div>
      </div>
    </div>
    <el-upload
      :limit='9'
      :action="uploadUrl"
      :show-file-list="false"
      list-type="picture"
      :on-success="uploadSuccess"
      :headers="headers"
      accept="image/gif, image/jpeg, image/jpg, image/png"
      class="upload-btn"
    >
      <img class="img-add" src="@/assets/images/upload/add.png" alt />
    </el-upload>
    
  <el-dialog :visible.sync="isShowImageDialog" @closed="clearImg" :modal-append-to-body="false" append-to-body>
    <PreviewImg :imgList="list" :imgIndex="imgIndex"></PreviewImg>
  </el-dialog>  
  </div>
</template>

<script>
import PreviewImg from "./PreviewImg.vue"
const buildConfig = require("@/build/" + process.env.BUILD_ENV + ".js");

export default {
  components:{
    PreviewImg
  },
  data() {
    return {
      uploadUrl: buildConfig.BASE_URL + "/api/app/public/img",
      headers: { Authorization: "Bearer " + localStorage.studentToken },
      list: [],
      index:0,
      isShowImageDialog: false
    };
  },
  methods: {
    delImg(index) {
      this.list.splice(index, 1);
      this.emit();
    },
    showImg(index){
      this.imgIndex = index;
      this.isShowImageDialog = true;
    },
    emit() {
      let str = this.list.length == 0 ? "" : this.list.join(",");
      this.$emit("imgPath", str);
    },
    async uploadSuccess(response) {
      if (response.code != 200) {
        await this.$error(response.msg);
      } else {
        this.list.push(response.data.path);
        this.emit();
      }
    },
    reset(){
      this.list = [];
      this.emit();
    },
    clearImg(){
      this.isShowImageDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .img-item {
    width: 28%;
    height: 0;
    padding-bottom: 28%;
    margin: 2.6%;
    position: relative;
    border-radius: 5px;
    .img-box {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      img {
        max-width: 100%;
        max-height: 100%;
        position: relative;
        left: 0;
        top: 0;
      }

      &:hover {
        .img-shadow {
          display: block;
        }
      }
      .img-shadow {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background: rgba($color: #000000, $alpha: 0.5);
        display: none;
        .img-del {
          position: absolute;
          left: calc(100% - 9px);
          top: -9px;
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
        .img-show {
          width: 22px;
          height: 24px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }
  .upload-btn {
    width: 120px;
    height: 120px;
    margin: 2.6%;
  }
  .img-add {
    width: 120px;
    height: 120px;
  }
}
</style>