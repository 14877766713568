<template>
  <div class="main-container">
    <!-- 面包屑 -->
    <div class="question-nav">
      <router-link :to="{name:'Course'}">首页</router-link>
      <span class="slash"> > </span>
      <span class="current">问答列表</span>
    </div>
    <!-- 分类 -->
    <div class="select-tab margin_bot_20">
      <Classify ref="classify" :classify_id="classify_id" @changeClassify="changeClassify" :all="true"/>
      <ul class="sort clear">
        <li :class="{'active':sort=='0'}" @click="changeSort('0')">综合排序</li>
        <li :class="{'active':sort=='1'}" @click="changeSort('1')">最新</li>
        <li :class="{'active':sort=='2'}" @click="changeSort('2')">最热</li>
        <li class="sort-price" :class="{'active':sort=='3'}" @click="changeSort('3')">浏览量</li>
        <li class="sort-price" :class="{'active':sort=='4'}" @click="changeSort('4')">回答量</li>
      </ul>
    </div>
    <!-- 列表 -->
    <div class="question-left f-left">
      <div class="question-search-box">
        <input type="text" v-model="keywords" placeholder="请输入关键字" />
        <div class="btn" @click="handleCurrentChange(1)">搜索问答</div>
        <div class="btn" @click="showQuestionModal=true">我要提问</div>
        <router-link :to='{name:"MemberQuestion"}' class="btn btn-danger">我的问答</router-link>
      </div>

      <div class="question-box">
        <div v-for="(item,index) in list" :key="index">
          <router-link class="question-item" :to="{name:'QuestionShow',params:{id:item.id}}">
            <div class="question-item-left">
              <img :src="item.avatar" alt />
            </div>
            <div class="question-item-right">
              <h3 class="question-item-name">
                {{item.nickname}}
                <span>{{item.created_at}}</span>
              </h3>
              <p class="question-item-title" v-html="item.title"></p>
              <p class="question-item-content" v-html="item.content"></p>
              <div class="question-item-operate">
                <span>
                  <img src="@/assets/images/news/read-count@3x.png" alt />
                  {{item.browse_number}}
                </span>
                <span>
                  <img src="@/assets/images/news/comment@3x.png" alt />
                  {{item.answer_number}}
                </span>
                <span>
                  <img src="@/assets/images/news/dianzan@3x.png" alt />
                  {{item.like_number}}
                </span>
              </div>
            </div>
          </router-link>
        </div>
        <Empty v-if="list.length==0" class="empty" title="暂无提问！" />
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="limit"
          layout="prev, pager, next, total, jumper"
          :total="total"
          v-if="list.length>0"
        ></el-pagination>
      </div>
    </div>
    <!-- 讲师列表 -->
    <div class="teacher_list f-left">
      <p>优秀答疑讲师</p>
      <ul class="clear teacher" v-if="teachers.length<=6">
        <li
          class="teacher-item"
          v-for="teacher in teachers"
          :key="teacher.id"
          :title="teacher.teacher_name"
        >
          <router-link
            :to="{name:'ShowTeachersDetaial',params:{id:teacher.id},query:{route_name:'ShowTeachers',route_label:'名师'}}"
          >
            <img class="teacher-avatar" :src="teacher.avatar" />
            <div class="teacher-name">{{teacher.real_name}}</div>
            <div class="tea_answer_num">回答问题数：{{teacher.num}}</div>
           <div class="teacher-descroption">{{teacher.introduction}}</div>
          </router-link>
        </li>
      </ul>
      <swiper class="swiper-box teacher" v-if="teachers.length>6" :options="swiperOption">
        <div
          class="teacher-item swiper-slide"
          v-for="teacher in teachers"
          :key="teacher.id"
          :title="teacher.teacher_name"
        >
          <router-link :to="{name:'OtoTeacherShow',params:{id:teacher.teacher_id}}">
            <img class="teacher-avatar" :src="teacher.avatar" />
            <div class="teacher-name">{{teacher.real_name}}</div>
            <div class="tea_answer_num">回答问题数：{{teacher.num}}</div>
          </router-link>
        </div>
      </swiper>
      <Empty v-if="!teachers.length" :img-width="130" :tb-padding="100"></Empty>
    </div>
    <div style="clear:both;"></div>

    <!-- 提问dialog -->
    <el-dialog title="提问" :visible.sync="showQuestionModal" width="576px" class="question-dialog" :modal-append-to-body="false">
      <el-form :model="questionData" :rules="rules" ref="questionForm">
        <el-form-item label="选择分类" prop="classify_id">
          <ChoseClassify @setClassifyId="setClassifyId" ref="choseClassify"></ChoseClassify>
        </el-form-item>
        <el-form-item prop="title">
          <el-input
            class="title-ipt"
            v-model="questionData.title"
            placeholder="请输入问题标题"
            maxlength="50"
          ></el-input>
          <span class="title-number">({{questionData.title.length}}/50)</span>
        </el-form-item>
        <el-form-item prop="content">
          <el-input type="textarea" :rows="4" placeholder="提出你的问题，会有老师来专门解答哦！" v-model="questionData.content"></el-input>
        </el-form-item>
        <el-form-item>
          <UploadImg @imgPath="imgPath" ref="uploadImg"></UploadImg>
        </el-form-item>
        <el-form-item class="form-btn-box">
          <el-button type="primary" @click="punishQuestion">发布问题</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Classify from "../../components/Classify.vue";
import ChoseClassify from "../../components/ChoseClassify.vue";
import UploadImg from "../../components/UploadImg.vue"
import Empty from "@/components/Empty";

export default {
  name: "Question",
  components: { Classify, ChoseClassify, UploadImg, Empty},
  data() {
    return {
      subClassify: 0,
      classify_id: 0,
      sort: "0",
      page: 1,
      keywords: "",
      limit: 10,
      total: 0,
      list: [],
      teachers: [],
      swiperOption: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        loop: true,
        observer: true,
        observeParents: true,
        slidesPerView: 6,
        slidesPerGroup: 1,
        spaceBetween: 16
      },

      showQuestionModal: false,
      commentClassify: [],
      questionData: {
        classify_id: "",
        title: "",
        content: "",
        images: ''
      },
      rules: {
        classify_id: [{ required: true, message: "请选择分类" }],
        title: [
          { required: true, message: "标题不能为空", trigger: "blur" }
        ],
        content: [{ required: true, message: "问题不能为空", trigger: "blur" }]
      },
    };
  },
  methods: {
    async changeClassify(flag, item) {
      console.log(flag, item);
      let id = item.id;
      if (flag == 1) {
        this.classify_id = item.id;
        this.subClassify = null;
        this.$refs.classify.showChildClassify = true;
        this.$nextTick(() => {
          this.$refs.classify.childClassify = item.child;
        });
      } else {
        this.subClassify = item.id;
      }
      this.page = 1;
      await this.getQuestionList();
      await this.getTeachers();
    },
    async changeSort(sort) {
      this.sort = sort;
      this.page = 1;
      await this.getQuestionList();
    },
    async getTeachers(){
      await this.$http.get("/api/app/ask/excellentTeachers", {  
        classify_id: this.subClassify || this.classify_id
      }).then(res=>{
        this.teachers = res;
      });
    },
    async getQuestionList() {
      let data = await this.$http.get("/api/app/ask/jaskList", {
        orderBy: this.sort,
        classify_id: this.subClassify || this.classify_id,
        page: this.page,
        limit: this.limit,
        keywords: this.keywords
      });
      this.list = data.list;
      this.total = data.total;
    },
    async handleCurrentChange(page) {
      this.page = page;
      await this.getQuestionList();
    },
    imgPath(e){
      this.questionData.images = e;
    },
    setClassifyId(e){
      this.questionData.classify_id = e;
    },
    // 发布问题
    punishQuestion(){
      this.$refs["questionForm"].validate(async valid => {
        if (!valid) {
          return;
        }
        await this.$http.post("/api/app/ask/jask", this.questionData);
        await this.$success("保存成功");
        this.questionData = {
          classify_id: "",
          title: "",
          content: "",
          images: ''
        },
        this.$refs.questionForm.clearValidate();
        this.$refs.questionForm.resetFields();
        await this.getQuestionList();
        await this.$refs.choseClassify.reset();
        await this.$refs.uploadImg.reset();
        await (this.showQuestionModal = false);

      });
    }
  },
  async created() {
    await this.getQuestionList();
    await this.getTeachers();
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/course-detail.scss";
</style>
<style lang="scss" scoped>
@import "../../css/question.scss";
</style>