<template>
  <div class="chose-classify">
    <el-select v-model="f_index" @change="changeClassifyOne">
      <el-option
        v-for="(item,index) in commentClassify"
        :value="index"
        :key="index"
        :label="item.title"
      ></el-option>
    </el-select>
    <el-select v-model="s_index" @change="changeClassifyTwo">
      <el-option
        v-for="(item,index) in childClassify"
        :value="index"
        :key="index"
        :label="item.title"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  data() {
    return {
      childClassify: [],
      commentClassify: [],
      f_index: 0,
      s_index: '',
      classify_id: ''
    };
  },
  async created() {
    await this.getCommentClassify(); //获取专业分类
  },
  methods: {
    changeClassifyOne() {
        this.childClassify = this.commentClassify[this.f_index].child;
        this.classify_id = this.commentClassify[this.f_index].id;
        this.emit();
    },
    changeClassifyTwo() {
        this.classify_id = this.childClassify[this.s_index].id;
        this.emit();
    },
    emit(){
      this.$emit('setClassifyId',this.classify_id);
    },
    getCommentClassify() {
      this.$http.get("/api/app/comment/classify").then(data => {
        data.unshift({title:'全部',id:0,child:[]})
        this.commentClassify = data;
      });
    },
    reset(){
      this.f_index = 0;
      this.s_index = '';
      this.changeClassifyOne();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.chose-classify {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-select{
      width: 45%;
  }
}
</style>
