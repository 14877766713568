<template>
  <div class="main-container">
    <!-- 面包屑 -->
    <div class="question-nav">
      <router-link :to="{name:'Index'}">首页</router-link>
      <span class="slash"> > </span>
      <router-link :to="{name:'Question'}">问答</router-link>
      <span class="slash"> > </span>
      <span class="current">问答详情</span>
    </div>

    <!-- 列表 -->
    <div class="question-left f-left forbid">
      <!-- 详情 -->
      <div class="question-detail">
        <div class="question-detail-top">
          <img class="question-avatar"
               :src="info.avatar"
               alt />
          <div class="question-right">
            <h3>
              {{info.nickname}}
              <span>
                <span class="margin_right_40"
                      @click="triggerCollect()">
                  <img class="question-collect"
                       src="@/assets/images/course/collect-none@2x.png"
                       alt
                       v-if="info.is_collection == 0" />
                  <img v-else
                       class="question-collect"
                       src="@/assets/images/course/collect@3x.png"
                       alt="">
                </span>

                <span @click="triggerPause()">
                  <img v-if="info.is_like==1"
                       class="question-pause"
                       src="@/assets/images/news/dianzan_active.png"
                       alt />
                  <img v-else
                       class="question-pause"
                       src="@/assets/images/news/dianzan@3x.png"
                       alt />
                  {{info.like_number}}
                </span>
                <i v-if="info.del_flag==0" @click="delete_question()" class="el-icon-delete" style="margin-left:24px;"></i>
              </span>
            </h3>
            <p class="question-time">{{info.created_at}}</p>
          </div>
        </div>
        <p class="question-title" v-html="info.title"></p>
        <p class="question-content" v-html="info.content"></p>
        <div class="question-img-box"
             v-if="info.images != ''">
          <div class="question-img"
               v-for="(item,index) in stringToArray(info.images)"
               :key="index">
            <img :src="item"
                 alt
                 @click="showPreview(stringToArray(info.images),index)" />
          </div>
        </div>
      </div>

      <!-- 回答 -->
      <div class="answer-box">
        <h3 class="answer-top-title">
          <span>全部回答</span>
        </h3>
        <div class="answer-detail"
             v-for="(item,index) in answerList"
             :key="index">
          <div class="answer-detail-top">
            <img class="answer-avatar"
                 :src="item.avatar"
                 alt />
            <div class="answer-right">
              <h3>
                {{item.user_name}}
                <span @click.stop="triggerSonPause(item.id,index,1)">
                  <img v-if="item.is_like==1"
                       class="answer-pause"
                       src="@/assets/images/news/dianzan_active.png"
                       alt />
                  <img v-else
                       class="answer-pause"
                       src="@/assets/images/news/dianzan@3x.png"
                       alt />
                  {{item.like_number}}
                </span>
              </h3>
              <p class="answer-time">{{item.created_at}}</p>
              <!-- <p class="answer-title"></p> -->
              <!-- free==0免费，其他值是表示价格 -->
              <div v-if="!item.free">
                <p class="answer-content" v-html="item.content"></p>

                <div>
                  <el-button type="primary"
                             v-show="item.showBtn"
                             @click="triggerBtn(1,index)">追问</el-button>
                  <el-button style="margin-left:0"
                             v-show="!item.showBtn"
                             @click="triggerBtn(2,index)">收起</el-button>
                </div>

                <div class="answer-img-box"
                     v-if="item.images != ''">
                  <div class="answer-img"
                       v-for="(son,index) in stringToArray(item.images)"
                       :key="index">
                    <img :src="son"
                         alt
                         @click="showPreview(stringToArray(item.images),index)" />
                  </div>
                </div>
              </div>
              <!-- 付费回答 -->
              <div v-else
                   class="no-free">
                <el-button type="primary"
                           @click="questionPay(item)"
                           style="margin:18px 0 14px 0;">
                  付费围观
                  <span> ￥{{item.free | toFixPrice}}元</span>
                </el-button>
                <br />
                <span style="font-size:14px;">{{item.circusee_number}}人已围观</span>
              </div>
            </div>
          </div>

          <!-- 追问 -->
          <div class="follow-box"
               v-if="item.child.total>0">
            <div class="follow-item"
                 v-for="(son,sonIndex) in item.child.data"
                 :key="sonIndex">
              <div class="follow-avatar">
                <img :src="son.avatar"
                     alt />
              </div>
              <div class="follow-right">
                <h3>
                  {{son.user_name}}
                  <span @click.stop="triggerSonPause(son.id,index,2,sonIndex)">
                    <img v-if="son.is_like==1"
                         class="follow-pause"
                         src="@/assets/images/news/dianzan_active.png"
                         alt />
                    <img v-else
                         class="follow-pause"
                         src="@/assets/images/news/dianzan@3x.png"
                         alt />
                    {{son.like_number}}
                  </span>
                </h3>
                <p class="follow-time">{{son.created_at}}</p>
                <p class="follow-content">
                  <span v-if="son.ait">回复<span class="ait">@{{son.ait}}</span>
                  </span>
                  <span v-html="son.content"></span>
                </p>
              </div>
            </div>
            <el-pagination v-if="item.child.total > childLimit" background
                           @current-change="((val)=>{childCurrentChange(val,item,index)})"
                           :current-page="item.child.current_page"
                           :page-size="childLimit"
                           layout="prev, pager, next, total, jumper"
                           :total="item.child.total"></el-pagination>
            <div style="clear:both;"></div>
          </div>

          <!-- 追问回复框 -->
          <div class="follow-apply"
               v-show="!item.showBtn">
            <el-input type="textarea"
                      :rows="4"
                      placeholder="提出你的问题，会有老师来专门解答哦！"
                      v-model="item.textarea"></el-input>
            <el-button type="primary"
                       style="margin-top:18px;"
                       @click="reApply(item,index)">追问</el-button>
            <div style="clear:both;"></div>
          </div>
        </div>

        <Empty v-if="answerList.length==0"
               class="empty"
               title="暂无提问！" />
        <el-pagination   background
                       @current-change="handleCurrentChange"
                       :current-page="page"
                       :page-size="limit"
                       layout="prev, pager, next, total, jumper"
                       :total="total"
                       v-if="total > limit && answerList.length>0"></el-pagination>

      </div>
    </div>

    <!-- 讲师列表 -->
    <div class="teacher_list f-left">
      <p>优秀答疑讲师</p>
      <ul class="clear teacher"
          v-if="teachers.length<=6">
        <li class="teacher-item"
            v-for="teacher in teachers"
            :key="teacher.id"
            :title="teacher.teacher_name">
          <router-link :to="{name:'ShowTeachersDetaial',params:{id:teacher.id},query:{route_name:'ShowTeachers',route_label:'名师'}}">
            <img class="teacher-avatar"
                 :src="teacher.avatar" />
            <div class="teacher-name">{{teacher.real_name}}</div>
            <div class="tea_answer_num">回答问题数：{{teacher.num}}</div>
            <div class="teacher-descroption">{{teacher.introduction}}</div>
          </router-link>
        </li>
      </ul>
      <swiper class="swiper-box teacher"
              v-if="teachers.length>6"
              :options="swiperOption">
        <div class="teacher-item swiper-slide"
             v-for="teacher in teachers"
             :key="teacher.id"
             :title="teacher.teacher_name">
          <router-link :to="{name:'OtoTeacherShow',params:{id:teacher.teacher_id}}">
            <img class="teacher-avatar"
                 :src="teacher.avatar" />
            <div class="teacher-name">{{teacher.real_name}}</div>
            <div class="tea_answer_num">回答问题数：{{teacher.num}}</div>
          </router-link>
        </div>
      </swiper>
      <Empty v-if="!teachers.length" :img-width="130" :tb-padding="100"></Empty>
    </div>
    <div style="clear:both;"></div>
    <el-dialog :visible.sync="isShowImageDialog"
               @closed="clearImg"
               :modal-append-to-body="false"
               append-to-body>
      <PreviewImg :imgList="prviewList"
                  :imgIndex="previewIndex"></PreviewImg>
    </el-dialog>
  </div>
</template>

<script>
import PreviewImg from "../../components/PreviewImg"
import Empty from "@/components/Empty";

export default {
  name: "QuestionShow",
  components: { PreviewImg,Empty },
  data () {
    return {
      teachers: [],
      swiperOption: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        loop: true,
        observer: true,
        observeParents: true,
        slidesPerView: 6,
        slidesPerGroup: 1,
        spaceBetween: 16
      },

      info: {},
      answerList: [],
      page: 1,
      limit: 6,
      childLimit: 5,
      total: 0,
      prviewList: [],
      previewIndex: 0,
      isShowImageDialog: false,
    };
  },
  async created () {
    await this.getInfo();
    await this.getAnswer()
    await this.getTeachers();
  },
  methods: {
    stringToArray (str) {
      if (str == "" || str == null || str == undefined) {
        return [];
      } else {
        let arr = str.split(",");
        return arr;
      }
    },
    triggerBtn (_type, _index) {
      let item = this.answerList[_index];
      item.showBtn = _type == 1 ? false : true;
      // this.$set(this.answerList._index,item)
    },
    async reApply (_item, index) {
      await this.$http.post('/api/app/ask/jaskQuestioning', {
        parent_id: _item.id,
        content: _item.textarea
      })
      await this.$success("追问成功");
      let data = await this.getChildAnswer(_item.id, 1);
      let fData = _item;
      fData.child = data;
      fData.showBtn = true;
      _item.textarea = '';
      this.$set(this.answerList, index, fData)
    },
    async getInfo () {
      let data = await this.$http.get("/api/app/ask/jaskDetail", {
        id: this.$route.params.id
      });
      this.info = data;
    },
    async handleCurrentChange (page) {
      this.page = page;
      await this.getAnswer();
    },
    async getAnswer () {
      let data = await this.$http.get("/api/app/ask/jaskDetailList", {
        id: this.$route.params.id,
        page: this.page,
        limit: this.limit,
        childLimit: this.childLimit
      });
      data.list.forEach(item => {
        item.showBtn = true;
        item.textarea = '';
      })
      this.answerList = data.list;
      this.total = data.total;
    },
    async getTeachers (){
      await this.$http.get("/api/app/ask/excellentTeachers", {
        ask_id: this.$route.params.id
      }).then(res=>{
        this.teachers = res;
      });
    },
    getChildAnswer (_id, _page) {
      let data = this.$http.get('/api/app/ask/jaskChildDetailList', {
        id: _id,
        page: _page,
        limit: this.childLimit
      })
      return data;
    },
    async childCurrentChange (page, item, index) {
      console.log(item, index, page);
      let data = await this.getChildAnswer(item.id, page);
      let fData = this.answerList[index];
      fData.child = data;
      this.$set(this.answerList, index, fData)
    },
    async triggerCollect () {
      await this.$http.post("/api/app/ask/clickCollection", {
        ask_id: this.$route.params.id
      });
      await this.getInfo();
    },
    async triggerPause () {
      await this.$http.post("/api/app/ask/clickLike", {
        ask_id: this.$route.params.id,
        comment_id: 0,
        user_type: 1
      });
      await this.getInfo();
    },
    async triggerSonPause (_id, _index, _type, _sonIndex) {
      let data = await this.$http.post("/api/app/ask/clickLike", {
        ask_id: this.$route.params.id,
        comment_id: _id,
        user_type: 1
      });
      let item = this.answerList[_index];
      if (_type == 1) {
        item.is_like = data.error.is_like;
        item.like_number = data.error.like_number;
      } else {
        item.child.data[_sonIndex].is_like = data.error.is_like;
        item.child.data[_sonIndex].like_number = data.error.like_number;
      }
      this.$set(this.answerList, _index, item)
    },
    clearImg () {
      this.isShowImageDialog = false;
    },
    showPreview (list, index) {
      this.prviewList = list;
      console.log(list)
      this.previewIndex = index * 1;
      this.isShowImageDialog = true;
    },
    questionPay (item) {
      this.$router.push({ name: 'QuestionPay', params: { ask_id: item.ask_id, id: item.id } })
    },
    delete_question(){
      this.$confirm('确定删除问题吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete('/api/app/ask/delMyAsk', { id: this.$route.params.id }).then(res => {
          this.$router.go(-1);
        })
      }).catch(() => {
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../css/course-detail.scss";
</style>
<style lang="scss" scoped>
@import "../../css/question.scss";
</style>
